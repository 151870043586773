/* eslint-disable no-underscore-dangle */

'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

declare global {
  interface Window {
    _paq?: {
      push: (params: string[]) => void;
    };
  }
}

function MatomoTrackPageView() {
  const pathname = usePathname();
  const initialRef = useRef(true);

  useEffect(() => {
    if (!initialRef.current) {
      window._paq?.push(['setCustomUrl', pathname]);
      window._paq?.push(['trackPageView']);
    }
    initialRef.current = false;
  }, [pathname]);

  return null;
}

export default MatomoTrackPageView;
